@import (reference) "framework/variables";

@media print {
    //Hide
    .top-links,
    .jumpMenu,
    .tools,
    .contact,
    .newsletter,
    .parallax-mirror,
    .parallax-window,
    .carouselGalerieWrap,
    .wrapSearch,
    .video-block,
    #stratis,
    .carrousel2,
    .articleSide,
    .cookiesTopBloc,
    .pagebrowser,
    .blocPartager,
    .albumsList .listItems .item.item-video .wrapImg:before,
    .catmenu-container,
    .buttons-top,
    .linkViewWrap .wrap,
    .en-images-container,
    .listType3 .item:before,
    .listType3 .item:after,
    .navigations,
    .bottomMenu,
    .bottomInfos img {
        display:none !important;
    }
    div,
    span {
        background:transparent !important;
    }
    img {
        max-width: 500px !important;
    }
    //Default colors
    h1,
    h2,
    h3,
    h4,
    h5,
    strong,
    span,
    h6,
    .content th,
    .content thead th,
    .content caption:after,
    .content ul > li:before,
    .content ol > li:before,
    .page-footer ul > li:before,
    .bottomMenu li:before,
    .content .classicalGallery .sliderGalerie .infosImg,
    .page-footer,
    .arrow-wrap .title:before,
    .topOfContent,
    .category,
    p {
        color:@color4-2 !important;
        background:transparent !important;
    }
    //Margins Paddings
    .blocAgenda .date,
    .listItems .category,
    .content .classicalGallery .sliderGalerie .infosImg {
        padding-left: 0 !important;
        margin-left:0 !important;
        color:@color4-2 !important;
    }
    .page-footer .list-with-header,
    .page-footer {
        padding-top:0 !important;
        margin-top:0 !important;
    }
    .header-content,
    .header-list {
        margin-left:0 !important;
        left:0 !important;
        background:transparent !important;
        width:100% !important;
        > div {
            padding: 0 !important;
        }
    }
    //links
    a,
    .content .external {
        color:@color4-2 !important;
        text-decoration:underline !important;
        background:transparent !important;
        font-weight:@bold !important;
        &:focus,
        &:hover {
            color:@color4-2 !important;
            text-decoration:underline !important;
            background:transparent !important;
        }
    }
    .listType1.agendaList .appointment {
        text-align:left;
    }
    .listType2 .listItems .item,
    .item {
        margin-left:0 !important;
        display:block !important;
        width:100% !important;
        padding-left:0 !important;
        background:transparent !important;
    }
    .btn {
        padding:0 !important;
        margin:0 !important;
        background:transparent !important;
        border:none !important;
        color:@color4-2 !important;
        text-decoration:underline !important;
        display:block !important;
        width:100%;
        text-align:left !important;
        font-weight:@bold !important;
        &:before,
        &:after {
            display:none !important;
        }
    }
    //Custom
    .content .encadre {
        border:none !important;
        margin-left:0 !important;
        &:before {
            display:none;
        }
    }
    .title-with-icon,
    .page-heading {
        padding-left:0 !important;
        .ico,
        &:before {
            display:none;
        }
    }
    .content thead th,
    .content table {
        border-color:@color4-2 !important;
    }
    .content .classicalGallery .sliderGalerie a {
        text-align:left;
        height:417px;
    }
    .page-footer .row .col:first-child {
        width:100%;
        display:block;
        margin-bottom:60px;
    }
    .content .classicalGallery .sliderGalerie .item {
        height:auto;
    }
    .full-images-content {
        > div {
            width:100% !important;
            min-height: 0;
            background:transparent !important;
        }
        .caption {
            position:relative;
            width:100% !important;
            display:block;
            padding-left:10px !important;
        }
        .category {
            padding-left:0 !important;
        }
    }
    .blocListItems,
    .blocFocus {
        width:100% !important;
    }
    .wrap {
        margin-left:0 !important;
        background:transparent !important;
        padding-left:0 !important;
        width:100% !important;
        height:auto !important;
        border:none !important;
    }
    .wrapSection .row .column,
    .listType3 .listItems .doctor-item {
        width:100%;
        padding-left:0 !important;
    }
    .wrapSection .row .column {
        margin-bottom:30px;
    }
}
